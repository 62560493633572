import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, Renderer2, TemplateRef, ViewContainerRef, OnInit, Optional, Inject } from '@angular/core';
import { TOOLTIP_OVERLAY_CONFIG, TooltipOverlayConfig } from 'src/app/site-management/_services/token';
import { XEditorComponent } from 'src/app/shared/_components/x-editor/x-editor.component';
import { PositionOverlay } from '../constant';

@Directive({
  selector: '[custom-tooltip]'
})
export class CustomTooltipDirective implements OnInit {
  @Input() htmlString: string;
  @Input() templateRef: TemplateRef<any>;
  @Input() viewContainerRef: ViewContainerRef;
  @Input() panelClass: string | string[];
  @Input() hideOnMouseLeave = false;
  @Input() tooltipDisabled = false;
  @Input() customConfig: TooltipOverlayConfig;

  overlayRef: OverlayRef;
  showTooltipTimer = null;
  tooltipComponentPortal: ComponentPortal<XEditorComponent>;
  tooltipTemplatePortal: TemplatePortal<any>;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private renderer: Renderer2,

    @Optional()
    @Inject(TOOLTIP_OVERLAY_CONFIG)
    private config: TooltipOverlayConfig
  ) { }

  ngOnInit(): void {
    this.renderer.listen(this.elementRef.nativeElement, 'mouseenter', () => {
      if (!this.tooltipDisabled) {
        this.showTooltip();
      }
    });
    this.renderer.listen(this.elementRef.nativeElement, 'mouseleave', () => {
      if (!this.tooltipDisabled) {
        if (this.hideOnMouseLeave) {
          this.hideTooltip();
        }
        else {
          // hide tooltip after 200ms
          this.showTooltipTimer = setTimeout(() => {
            this.hideTooltip();
          }, 200);
        }
      }
    });
  }

  getConfig(elementRef: ElementRef): OverlayConfig {

    const positionPair = this.customConfig?.position || this.config?.position || [
      PositionOverlay.bottom,
      PositionOverlay.top,
      PositionOverlay.bottomLeft,
      PositionOverlay.bottomRight,
      PositionOverlay.topLeft,
      PositionOverlay.topRight,
    ];

    return {
      maxHeight: 500,
      height: 'auto',
      hasBackdrop: false,
      panelClass: this.panelClass || '',
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(elementRef)
        .withPositions(positionPair)
        .withFlexibleDimensions(true)
        .withViewportMargin(40)
        .withGrowAfterOpen(true)
        .withPush(false),
    };
  }

  showTooltip() {
    // setup overlay
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create(this.getConfig(this.elementRef));
    }

    if (this.overlayRef.hasAttached()) {
      this.hideTooltip();
    }

    if (this.templateRef && this.viewContainerRef) {
      // create TemplatePortal
      if (!this.tooltipTemplatePortal) {
        this.tooltipTemplatePortal = new TemplatePortal(this.templateRef, this.viewContainerRef);
      }
      this.overlayRef.attach(this.tooltipTemplatePortal);
    }
    else if (this.htmlString) {
      // create ComponentPortal
      if (!this.tooltipComponentPortal) {
        this.tooltipComponentPortal = new ComponentPortal(XEditorComponent);
      }
      const tooltipComponentRef = this.overlayRef.attach(this.tooltipComponentPortal);
      tooltipComponentRef.instance.value = this.htmlString;
    }
    else {
      return;
    }

    if (!this.hideOnMouseLeave) {
      this.observerOverlayRef();
    }
  }

  observerOverlayRef() {
    // don't hide tooltip when hover tooltip
    this.renderer.listen(this.overlayRef.overlayElement, 'mouseenter', () => {
      clearTimeout(this.showTooltipTimer);
    });

    // hide tooltip
    this.renderer.listen(this.overlayRef.overlayElement, 'mouseleave', () => {
      this.hideTooltip();
    });
  }

  hideTooltip() {
    this.overlayRef?.detach();
  }
}
