import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core';
import { TaskQueryParamStore } from './site-management/tasks/_services/task-query-param.store';
import { VersionComponent } from './shared/_components/version/version.component';
import { PreloaderAssets } from './shared/_components/preload-assets/preloader-assets.component';
import { NG_PROGRESS_CONFIG, NgProgressModule } from 'ngx-progressbar';
export let AppInjector: Injector;

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    NgProgressModule
  ],
  providers: [
    TaskQueryParamStore,
    {
      provide: NG_PROGRESS_CONFIG,
      useValue: {
        color: '#0053b3'
      }
    }
  ],
  bootstrap: [AppComponent, VersionComponent, PreloaderAssets]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
