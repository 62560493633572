import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { XDirectivesModule } from 'src/@xcorp/directives/x-directives.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    DropdownComponent,
    DropdownMenuComponent,
  ],
  imports: [
    CommonModule,
    XDirectivesModule,
    MatIconModule,
  ],
  exports: [
    DropdownComponent,
  ]
})
export class DropdownModule { }
